import _ from 'lodash';

/**
 * Creates an Error that you can pass a props object to which will then be applied to the Error itself.
 */
class CustomError extends Error {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'message' implicitly has an 'any' type.
  constructor(message, props = {}) {
    if (_.isString(message)) {
      super(message);
    } else {
      super();
    }

    // Check if someone passed the custom props object as the first argument to the constructor
    const customProps = _.isObject(message) ? message : props;

    // Apply each of the custom props passed to the error object
    _.forOwn(customProps, (prop, key) => {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomError'.
      this[key] = prop;
    });
  }
}

/**
 * Error to be thrown to represent missing data.
 * The main reason for the extension is so that toString looks like: "NotFoundError: All of the things were not found."
 */
class NotFoundError extends CustomError {}

export { CustomError, NotFoundError };
