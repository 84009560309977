import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

const getCmsUrl = () => process.env.NEXT_PUBLIC_CMS_URL;
const DEFAULT_ERROR_MESSAGE = 'We encountered an error submitting the form';

const craftBackend = axios.create({
  baseURL: `${getCmsUrl()}`,
});

/**
 * Submits a form to the backend
 */
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'submitForm' does not exist on type 'AxiosInstance'.
craftBackend.submitForm = (
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'path' implicitly has an 'any' type.
  path,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'data' implicitly has an 'any' type.
  data,
  config = {
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  }
) => {
  return craftBackend.post(path, qs.stringify(data), config);
};

// Axios interceptor to handle Craft returning a 200 responses with errors
craftBackend.interceptors.response.use(
  (response) => {
    const error = _.get(response, 'data.error');
    const errors = _.get(response, 'data.errors');
    const hasErrors = error || errors;

    // if error or errors key found in response throw an error
    if (hasErrors) {
      const errorMessage = error || DEFAULT_ERROR_MESSAGE;
      const craftError = new Error(errorMessage);
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'fieldErrors' does not exist on type 'Error'.
      craftError.fieldErrors = errors;
      return Promise.reject(craftError);
    }

    return response;
  },
  (error) => {
    // TODO: fix this to resolve the CORS error
    // This is a temporary fix.

    console.error({ error });

    // Replace default Axios error message with user friendly error
    // const modifiedError = error;
    // modifiedError.message = DEFAULT_ERROR_MESSAGE;
    // return Promise.reject(error);
    return { success: true };
  }
);

export default craftBackend;
