import _ from 'lodash';
import Rollbar from 'rollbar';

const logger = () => {
  const { ROLLBAR_ENV = 'development' } = process.env;
  const client = new Rollbar({
    accessToken: process.env.ROLLBAR_TOKEN,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    payload: {
      environment: ROLLBAR_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.CODE_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
  });

  // @NOTE: Patch on "warn" method so Rollbar follows same spec as console
  client.warn = client.warning;

  const outputs = [console, client];

  return {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Rest parameter 'args' implicitly has an 'any[]' type.
    error: (...args) => _.invokeMap(outputs, 'error', ...args),
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Rest parameter 'args' implicitly has an 'any[]' type.
    warn: (...args) => _.invokeMap(outputs, 'warn', ...args),
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Rest parameter 'args' implicitly has an 'any[]' type.
    info: (...args) => _.invokeMap(outputs, 'info', ...args),
  };
};

export default logger();
