import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import React from 'react';

import Breadcrumbs, { BreadcrumbsProps } from '@/components/breadcrumbs';

import {
  BreadcrumbsWrapper,
  ImageWrapper,
  Space1,
  StyledContainer,
  StyledImg,
  StyledHeading,
  StyledSectionHeading,
  StyledP,
  Wrapper,
} from './styles';
import { Box, useMediaQuery } from '@mui/system';

type ImageType = {
  title?: string;
  url: string;
  kind?: string;
};

type BackgroundImageType = {
  hasFocalPoint?: boolean;
  focalPoint: [number, number];
} & ImageType;

type PageHeaderBlockProps = {
  image?: ImageType;
  category?: string;
  heading?: string;
  subHeading?: string;
  breadcrumbs: BreadcrumbsProps['entry'];
  backgroundImage?: BackgroundImageType;
  textAlignment?: 'left' | 'right' | 'center';
  textColour?: string;
  search?: boolean;
  SearchComponent?: React.FC;
};

const alignItems = (textAlignment: PageHeaderBlockProps['textAlignment']): string => {
  switch (textAlignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

const PageHeaderBlock: React.FC<PageHeaderBlockProps> = ({
  image = null,
  category = '',
  heading = '',
  subHeading,
  breadcrumbs,
  backgroundImage,
  textAlignment,
  textColour,
  search = false,
  SearchComponent,
}) => {
  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const theme = useTheme();

  const isTabletDevice = useMediaQuery(theme.breakpoints.down('xl'));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (headingRef.current) {
      headingRef.current.focus();
    }
  }, [headingRef]);

  return (
    <Wrapper>
      {(!search || !isMobileDevice) && _.get(backgroundImage, 'url') && (
        <ImageWrapper
          role="img"
          aria-label={backgroundImage?.title || 'Header Background Image'}
          {...{ textAlignment, ..._.pick(backgroundImage, 'url', 'focalPoint', 'hasFocalPoint') }}
        />
      )}
      <StyledContainer maxWidth="xl">
        <BreadcrumbsWrapper role="navigation" aria-label="You are here">
          <Breadcrumbs entry={breadcrumbs} />
        </BreadcrumbsWrapper>
        <Grid container alignItems={search ? alignItems('left') : alignItems(textAlignment)} direction="column">
          <Grid item xs={12} lg={8}>
            {image && <StyledImg src={image.url} alt={image.title || ''} />}
            <Space1 />
            {!search && !!category && (
              <>
                <StyledP>{category}</StyledP>
                <Space1 />
              </>
            )}
            <Box maxWidth={search && isTabletDevice ? '80%' : '100%'}>
              <StyledHeading textColor={textColour} tabIndex={-1} ref={headingRef}>
                {heading}
              </StyledHeading>
            </Box>
            <Space1 />
            <StyledSectionHeading textColor={textColour}>{subHeading}</StyledSectionHeading>
          </Grid>
          {search && SearchComponent && <SearchComponent />}
        </Grid>
      </StyledContainer>
    </Wrapper>
  );
};

// @ts-expect-error - FIXME: Very weird property setting on a functional component. Worth looking into.
PageHeaderBlock.query = `
  ... on pageHeaderMatrix_pageHeaderBlock_BlockType {
    id
    typeHandle
    image {
      title
      url
      kind
    }
    preHeading
    heading
    subHeading
    textAlignment
    textColour
    backgroundImage {
      title
      url
      kind
      ... on AssetInterface {
        hasFocalPoint
        focalPoint
      }
    }
  }
`;

export default PageHeaderBlock;
