import { first, last } from 'lodash';

import { yearSliderMarks } from '../components/discovery-filter/constants';
import { priceRangeSliderMarks } from '../components/discovery-questions-input/utils/price-range-slider-marks';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PRICE_MIN = first(priceRangeSliderMarks)!.value;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const YEAR_MIN = first(yearSliderMarks)!.value;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const YEAR_MAX = last(yearSliderMarks)!.value;

export type CuratedFilter = {
  title: string;
  price: string;
  query: {
    price: [number, number];
    year: [number, number];
    safeCarsForFamilies?: boolean;
    pPlaterApproved?: boolean;
    bodyStyle?: string;
    powerPlantType?: string[];
  };
};

export type CuratedFilterKeys = 'P_PLATER_APPROVED' | 'SAFE_CARS_FOR_FAMILIES' | 'SUVS' | 'ELECTRIC_AND_HYBRID';

export const CURATED_FILTERS: Record<CuratedFilterKeys, CuratedFilter> = {
  P_PLATER_APPROVED: {
    title: 'Safe first car for P-platers',
    price: 'under $12,000',
    query: {
      price: [PRICE_MIN, 12000],
      pPlaterApproved: true,
      year: [YEAR_MIN, YEAR_MAX],
    },
  },
  SAFE_CARS_FOR_FAMILIES: {
    title: 'Safe cars for families',
    price: 'under $30,000',
    query: {
      price: [PRICE_MIN, 30000],
      safeCarsForFamilies: true,
      year: [YEAR_MIN, YEAR_MAX],
    },
  },
  SUVS: {
    title: 'Safe SUVs & 4WDs',
    price: 'under $60,000',
    query: {
      price: [PRICE_MIN, 60000],
      bodyStyle: 'suvs',
      year: [YEAR_MIN, YEAR_MAX],
    },
  },
  ELECTRIC_AND_HYBRID: {
    title: 'Safe electric or hybrid cars',
    price: 'under $50,000',
    query: {
      price: [PRICE_MIN, 50000],
      powerPlantType: ['Electric', 'Hybrid'],
      year: [YEAR_MIN, YEAR_MAX],
    },
  },
};
