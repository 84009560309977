import Link from 'next/link';
import React from 'react';

import LoadingIndicator from '../loading-indicator';

import { StyledCompareButton, StyledPrimaryButton, StyledSecondaryButton, StyledTertiaryButton } from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'Component' implicitly has an 'any' type.
const handleLinkWrapping = (Component, props) => {
  /*
    Using asPath instead of as prop here to avoid clashing with styled-components's
    reserved "as" prop: https://styled-components.com/docs/api#as-polymorphic-prop
  */
  const { asPath, href, query, target, children, disabled, isLoading, buttonAsDiv, ...rest } = props;

  const isInternalLink = href && href.startsWith('/');

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'onClick' does not exist on type '{}'.
  const LinkButton = React.forwardRef(({ onClick, href }, ref) => {
    return (
      <Component href={href} onClick={onClick} ref={ref} disabled={disabled} {...rest}>
        {isLoading ? <LoadingIndicator /> : children}
      </Component>
    );
  });
  LinkButton.displayName = 'LinkButton';

  if (href && !disabled) {
    if (isInternalLink) {
      return (
        <Link legacyBehavior passHref href={query ? { pathname: href, query } : href} as={asPath} {...rest}>
          <LinkButton />
        </Link>
      );
    }

    return (
      <Component
        component="a"
        href={href}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        {...rest}
      >
        {isLoading ? <LoadingIndicator /> : children}
      </Component>
    );
  }

  return (
    <Component disabled={disabled} component={buttonAsDiv && 'div'} {...rest}>
      {isLoading ? <LoadingIndicator /> : children}
    </Component>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const PrimaryButton = (props) => handleLinkWrapping(StyledPrimaryButton, props);
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const SecondaryButton = (props) => handleLinkWrapping(StyledSecondaryButton, props);
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const TertiaryButton = (props) => handleLinkWrapping(StyledTertiaryButton, props);
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const CompareButton = (props) => handleLinkWrapping(StyledCompareButton, props);

export { PrimaryButton, SecondaryButton, TertiaryButton, CompareButton };
