import React, { useState } from 'react';

import { useLookupNumberPlate } from '../../hooks/use-lookup-number-plate';
import { PrimaryButton } from '../button';

import {
  ExampleLabelText,
  NumberPlateButtonWrapper,
  NumberPlateErrorText,
  NumberPlateTextField,
  NumberPlateWrapper,
  TabPanel,
} from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'hidden' implicitly has an 'any' type.
export const NumberPlateTabPanel = ({ hidden }) => {
  return (
    <TabPanel hidden={hidden}>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: string; for: string; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & LabelHTMLAttributes<...>'. */}
      <ExampleLabelText for="rego-id-search">Enter a Victorian Number Plate</ExampleLabelText>
      <NumberPlateWrapper>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'errorSecondaryPosition' is missing in type '{}' but required in type '{ errorSecondaryPosition: any; }'. */}
        <NumberPlateSearchBox />
      </NumberPlateWrapper>
    </TabPanel>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'errorSecondaryPosition' implicitly has an 'any' type.
export const NumberPlateSearchBox = ({ errorSecondaryPosition }) => {
  const [numberPlateInput, setNumberPlateInput] = useState('');

  const { numberPlateNotFound, onSubmitNumberPlate, setNumberPlateNotFound, submittingNumberPlate } =
    useLookupNumberPlate();

  const isSubmittingNumberPlate = submittingNumberPlate.current;

  const submitDisabled = !numberPlateInput;

  const submitNumberPlateInput = () => {
    if (submitDisabled) {
      return;
    }

    onSubmitNumberPlate(numberPlateInput);
  };

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'input' implicitly has an 'any' type.
  const verifyNumberPlateInput = (input) => {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'current' does not exist on type 'boolean'.
    if (isSubmittingNumberPlate.current) {
      return;
    }

    setNumberPlateNotFound(false);

    const numbersAndLettersRegex = /[a-zA-Z0-9]/g;
    const result = input.match(numbersAndLettersRegex) || [];
    setNumberPlateInput(result.join('').toUpperCase());
  };

  return (
    <>
      <NumberPlateTextField
        inputProps={{ id: 'rego-id-search', maxLength: '6' }}
        value={numberPlateInput}
        placeholder="ABC123"
        onChange={(e) => verifyNumberPlateInput(e.target.value)}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            submitNumberPlateInput();
          }
        }}
      />

      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: (string | Element)[]; hidden: boolean; isSecondaryPosition: any; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...> & { ...; }'. */}
      <NumberPlateErrorText hidden={!numberPlateNotFound} isSecondaryPosition={errorSecondaryPosition}>
        We couldn&apos;t find any results matching <strong>{numberPlateInput}</strong>. Please enter a different
        registration or try searching by Make or Model
      </NumberPlateErrorText>
      <NumberPlateButtonWrapper>
        <PrimaryButton
          disabled={submitDisabled}
          isLoading={isSubmittingNumberPlate && !numberPlateNotFound}
          fullWidth
          onClick={submitNumberPlateInput}
        >
          Search
        </PrimaryButton>
      </NumberPlateButtonWrapper>
    </>
  );
};
