import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';

import { COLORS, BOX_SHADOW } from '../../constants';

const expansionPanelBorderRadius = { borderRadius: 0 };

const StyledAccordionFaq = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: ${BOX_SHADOW.DEFAULT};
    &:hover {
      box-shadow: ${BOX_SHADOW.HOVER};
    }

    &.Mui-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.rounded {
    &.last-child: {
      border-radius: 0px;
    }
  }
`;

const StyledAccordionSummaryFaq = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 48px;
    margin: 0;

    &:hover {
      background-color: ${COLORS.GREY_FOUR};
      box-shadow: inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX};
      border-radius: 1px;
    }

    .MuiSvgIcon-root {
      font-size: 44px;

      ${
        /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'breakpoints' does not exist on type 'Theme'. */
        (props) => props.theme.breakpoints.down('sm')
      } {
        font-size: 36px;
      }
    }

    ${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'breakpoints' does not exist on type 'Theme'. */
      (props) => props.theme.breakpoints.down('sm')
    } {
      padding-right: 24px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;

const DividerFaq = styled.div`
  height: 3px;
  background-color: ${COLORS.GREY_THREE};
`;

const Divider = styled.div`
  height: 3px;
  background-color: ${COLORS.GREY_FOUR};
`;

export {
  expansionPanelBorderRadius,
  StyledAccordionFaq,
  StyledAccordionSummaryFaq,
  StyledAccordionDetails,
  DividerFaq,
  Divider,
};
