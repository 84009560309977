import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { LOCALSTORAGE_KEYS } from '../../constants';
import Minicart from '../mini-cart';
import { CART_MAXIMUM } from '../mini-cart/constants';
import { getVehicles } from '../../utils/apollo-requests/vehicles/get-vehicles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicleIds' implicitly has an 'any' type.
const addVehicleIdsToLocalStorage = (vehicleIds) => {
  if (vehicleIds && vehicleIds.length) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem(LOCALSTORAGE_KEYS.COMPARE, JSON.stringify(vehicleIds));
      } catch (e) {
        // localStorage is disabled
        console.error('Failed to add vehicle ID to local storage', e);
      }
    }
  }
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterActions' implicitly has an 'any' type.
const MiniCartWrapper = ({ compareVehicleIds = [], filterActions, isOpen = false, onClose, removeFromCompare }) => {
  const compareVehicleCount = _.size(compareVehicleIds);
  const [VehicleData, setVehicleData] = useState([]);

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Variable 'vehicleIds' implicitly has type 'any' in some locations where its type cannot be determined.
  let vehicleIds;
  if (!_.isEmpty(compareVehicleIds)) {
    vehicleIds = compareVehicleIds;
  }

  useEffect(() => {
    let isSubscribed = true;

    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Variable 'vehicleIds' implicitly has an 'any' type.
    addVehicleIdsToLocalStorage(vehicleIds);

    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Variable 'vehicleIds' implicitly has an 'any' type.
    if (vehicleIds) {
      const getCarData = async () => {
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type '{ vehicleIds: any; }' is not assignable to parameter of type '{ make: any; model: any; year: any; vehicleIds: any; }'.
        const query = await getVehicles({ vehicleIds });
        if (isSubscribed) {
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'vehicles' does not exist on type 'string | { vehicles: any; }'.
          setVehicleData(query.vehicles);
        }
      };
      getCarData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [vehicleIds]);

  const vehicles = vehicleIds ? VehicleData : [];

  let alertMessage = '';
  if (compareVehicleCount === 1) {
    alertMessage = "There aren't enough cars in your list to compare. Select one more.";
  } else if (compareVehicleCount > CART_MAXIMUM) {
    alertMessage = 'Too many vehicles to compare. The maximum number of vehicles is 6.';
  }

  return (
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ filterActions: any; isOpen: boolean; removeFromCompare: any; vehicles: never[]; vehicleIds: never[] | undefined; compareVehicleCount: number; onClose: any; alertMessage: string; }' is not assignable to type 'Pick<{ filterActions: any; isOpen?: boolean | undefined; onClose: any; alertMessage?: null | undefined; removeFromCompare: any; vehicleIds?: never[] | undefined; vehicles?: never[] | undefined; compareVehicleCount?: number | undefined; }, "vehicles" | ... 3 more ... | "compareVehicleCount">'.
    <Minicart
      {...{
        filterActions,
        isOpen,
        removeFromCompare,
        vehicles,
        vehicleIds,
        compareVehicleCount,
        onClose,
        alertMessage,
      }}
    />
  );
};

MiniCartWrapper.propTypes = {
  compareVehicleIds: PropTypes.arrayOf(PropTypes.string),
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
};

export default MiniCartWrapper;
