import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import Icon from '../icons';
import { BREAKPOINTS } from '../../constants';

import { fieldStyles, formTheme } from './field-styles';

const StyledMuiTextField = styled(MuiTextField)`
  ${fieldStyles.outline}
`;

// stylelint-disable property-no-vendor-prefix
// need to use this to stop chrome from restyling autofilled fields
const Wrapper = styled.div`
  input:-webkit-autofill {
    -webkit-text-fill-color: ${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
      formTheme.palette.secondary.light
    };
  }

  input:-webkit-autofill,
  input:-webkit-autofill:active,
  input:-webkit-autofill:hover {
    box-shadow: 0 0 0 30px ${formTheme.palette.background.main} inset !important;
  }

  .MuiInputBase-root {
    ${fieldStyles.root}
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }

  .MuiOutlinedInput-input {
    color: ${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
      formTheme.palette.secondary.light
    };

    &::placeholder {
      color: ${
        /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
        formTheme.palette.secondary.light
      };
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${formTheme.palette.primary.main};
  }

  .MuiInputLabel-root {
    ${fieldStyles.root}
    padding-right: 10px;
    color: ${formTheme.palette.secondary.main};
  }

  .MuiFormHelperText-root {
    ${fieldStyles.helperText}
    color: ${formTheme.palette.secondary.main};
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.secondary.main};
    }

    .MuiButtonBase-root {
      .MuiTouchRipple-root {
        color: ${
          /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
          formTheme.palette.secondary.light
        };
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${
          /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
          formTheme.palette.secondary.light
        };
      }
    }

    &.Mui-focused {
      border-color: ${formTheme.palette.primary.main};

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${formTheme.palette.primary.main};
      }
    }
  }

  ${(props) =>
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'error' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<...> & { ...; }'. Did you mean 'onError'?
    props.error &&
    `
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${formTheme.palette.error.main};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${formTheme.palette.error.main};
        }
      }

      &.Mui-focused {
        border-color: ${formTheme.palette.error.main};

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: ${formTheme.palette.error.main};
        }
      }
    }

    .MuiInputLabel-root {
      color: ${formTheme.palette.error.main};
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${formTheme.palette.error.main};
    }
  `}

  ${(props) =>
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'label' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<...> & { ...; }'.
    props.label !== '' &&
    `
    .MuiIconButton-root {
      padding: 5px;

      @media (min-width: ${BREAKPOINTS.LG}px) {
        padding: 10px;
      }
    }
  `}
`;

// stylelint-enable property-no-vendor-prefix

const StyledIcon = styled(Icon)`
  display: inline-block;
  fill: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
    formTheme.palette.secondary.light
  };
  flex-shrink: 0;
  height: 25px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  width: 25px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    height: 30px;
    width: 30px;
  }
`;

// eslint-disable-next-line react/display-name
const TextField = React.forwardRef(
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'tabIndex' does not exist on type '{}'.
  ({ tabIndex = undefined, type = 'text', shrinkLabel = undefined, ...otherProps }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const isTypePassword = type === 'password';

    let inputType = type;
    if (isTypePassword) {
      inputType = showPassword ? 'text' : 'password';
    }

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const adornment = isTypePassword ? (
      <InputAdornment position="end">
        <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword} size="large">
          <StyledIcon name={showPassword ? 'visibilityOff' : 'visibility'} />
        </IconButton>
      </InputAdornment>
    ) : null;

    return (
      <Wrapper>
        <StyledMuiTextField
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'ForwardedRef<unknown>' is not assignable to type '((instance: HTMLDivElement | null) => void | (() => VoidOrUndefinedOnly)) | RefObject<HTMLDivElement> | null | undefined'.
          ref={ref}
          variant="outlined"
          type={inputType}
          {...otherProps}
          InputProps={{
            endAdornment: adornment,
            inputProps: {
              tabIndex,
              pattern: "[A-Za-z0-9\\s!@#$%^&*()_+=-`~\\][{}|';:/.,?><]*",
              title: 'Only letters, numbers and some special characters accepted',
            },
          }}
          InputLabelProps={{
            shrink: shrinkLabel || undefined,
          }}
        />
      </Wrapper>
    );
  }
);

TextField.propTypes = {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object literal may only specify known properties, and 'tabIndex' does not exist in type 'WeakValidationMap<RefAttributes<unknown>>'.
  tabIndex: PropTypes.string,
  type: PropTypes.string,
  shrinkLabel: PropTypes.bool,
};

export default TextField;
